/* tslint:disable */
/* eslint-disable */
/**
 * User Manager API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Announcement
 */
export interface Announcement {
    /**
     * Announcement ID
     * @type {string}
     * @memberof Announcement
     */
    'id': string;
    /**
     * Date on which this announcement was created
     * @type {string}
     * @memberof Announcement
     */
    'createdOn': string;
    /**
     * Announcement title
     * @type {string}
     * @memberof Announcement
     */
    'title': string;
    /**
     * Announcement subtitle
     * @type {string}
     * @memberof Announcement
     */
    'subtitle'?: string;
    /**
     * Announcement details
     * @type {string}
     * @memberof Announcement
     */
    'description': string;
    /**
     * Day on which this announcement expires
     * @type {string}
     * @memberof Announcement
     */
    'expiresOn'?: string;
}
/**
 * 
 * @export
 * @interface AnnouncementId
 */
export interface AnnouncementId {
    /**
     * 
     * @type {string}
     * @memberof AnnouncementId
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface BackgroundImageItem
 */
export interface BackgroundImageItem {
    /**
     * 
     * @type {string}
     * @memberof BackgroundImageItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BackgroundImageItem
     */
    'src': string;
    /**
     * 
     * @type {number}
     * @memberof BackgroundImageItem
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof BackgroundImageItem
     */
    'height'?: number;
}
/**
 * The specified content was not found.
 * @export
 * @interface BadRequestError
 */
export interface BadRequestError {
    /**
     * 
     * @type {string}
     * @memberof BadRequestError
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof BadRequestError
     */
    'code': number;
    /**
     * 
     * @type {Array<InternalServerErrorErrors>}
     * @memberof BadRequestError
     */
    'errors'?: Array<InternalServerErrorErrors>;
}
/**
 * 
 * @export
 * @interface BasicUserInfoList
 */
export interface BasicUserInfoList {
    /**
     * 
     * @type {number}
     * @memberof BasicUserInfoList
     */
    'total': number;
    /**
     * 
     * @type {Array<User>}
     * @memberof BasicUserInfoList
     */
    'items': Array<User>;
    /**
     * 
     * @type {string}
     * @memberof BasicUserInfoList
     */
    'next': string | null;
}
/**
 * 
 * @export
 * @interface CarouselItem
 */
export interface CarouselItem {
    /**
     * 
     * @type {string}
     * @memberof CarouselItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CarouselItem
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselItem
     */
    'subtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof CarouselItem
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface FamilyId
 */
export interface FamilyId {
    /**
     * 
     * @type {string}
     * @memberof FamilyId
     */
    'familyId'?: string;
}
/**
 * User does not have the appropriate permissions to access this endpoint.
 * @export
 * @interface ForbiddenError
 */
export interface ForbiddenError {
    /**
     * 
     * @type {string}
     * @memberof ForbiddenError
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ForbiddenError
     */
    'code': number;
    /**
     * 
     * @type {Array<InternalServerErrorErrors>}
     * @memberof ForbiddenError
     */
    'errors'?: Array<InternalServerErrorErrors>;
}
/**
 * Unhandled internal server error has occurred.
 * @export
 * @interface InternalServerError
 */
export interface InternalServerError {
    /**
     * 
     * @type {string}
     * @memberof InternalServerError
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof InternalServerError
     */
    'code': number;
    /**
     * 
     * @type {Array<InternalServerErrorErrors>}
     * @memberof InternalServerError
     */
    'errors'?: Array<InternalServerErrorErrors>;
}
/**
 * 
 * @export
 * @interface InternalServerErrorErrors
 */
export interface InternalServerErrorErrors {
    /**
     * 
     * @type {number}
     * @memberof InternalServerErrorErrors
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof InternalServerErrorErrors
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof InternalServerErrorErrors
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'address': string;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'longitude': number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'apiKey': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'url': string;
}
/**
 * The specified content was not found.
 * @export
 * @interface NotFoundError
 */
export interface NotFoundError {
    /**
     * 
     * @type {string}
     * @memberof NotFoundError
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof NotFoundError
     */
    'code': number;
    /**
     * 
     * @type {Array<InternalServerErrorErrors>}
     * @memberof NotFoundError
     */
    'errors'?: Array<InternalServerErrorErrors>;
}
/**
 * 
 * @export
 * @interface PageContent
 */
export interface PageContent {
    /**
     * 
     * @type {string}
     * @memberof PageContent
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageContent
     */
    'title'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PageContent
     */
    'subtitles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PageContent
     */
    'expiresOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageContent
     */
    'html'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageContent
     */
    'backgroundImage'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageContent
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageContent
     */
    'updatedOn'?: string;
}
/**
 * User must be authenticated in order to access this endpoint.
 * @export
 * @interface UnauthorizedError
 */
export interface UnauthorizedError {
    /**
     * 
     * @type {string}
     * @memberof UnauthorizedError
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof UnauthorizedError
     */
    'code': number;
    /**
     * 
     * @type {Array<InternalServerErrorErrors>}
     * @memberof UnauthorizedError
     */
    'errors'?: Array<InternalServerErrorErrors>;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * A unique identifier
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * Display name of the user
     * @type {string}
     * @memberof User
     */
    'displayName': string;
    /**
     * First name
     * @type {string}
     * @memberof User
     */
    'firstName': string;
    /**
     * First name
     * @type {string}
     * @memberof User
     */
    'lastName'?: string;
    /**
     * Email ID of user
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * Indicates whether the user is approved in the system
     * @type {boolean}
     * @memberof User
     */
    'isApproved'?: boolean;
}
/**
 * 
 * @export
 * @interface UserData
 */
export interface UserData {
    /**
     * A unique identifier
     * @type {string}
     * @memberof UserData
     */
    'id'?: string;
    /**
     * Family ID to which user belongs to
     * @type {string}
     * @memberof UserData
     */
    'familyId'?: string;
    /**
     * Display name of the user
     * @type {string}
     * @memberof UserData
     */
    'displayName'?: string;
    /**
     * First name
     * @type {string}
     * @memberof UserData
     */
    'firstName': string;
    /**
     * Middle name
     * @type {string}
     * @memberof UserData
     */
    'middleName'?: string;
    /**
     * First name
     * @type {string}
     * @memberof UserData
     */
    'lastName': string;
    /**
     * Baptismal Name
     * @type {string}
     * @memberof UserData
     */
    'baptismalName'?: string;
    /**
     * House name
     * @type {string}
     * @memberof UserData
     */
    'houseName'?: string;
    /**
     * Family unit
     * @type {string}
     * @memberof UserData
     */
    'familyUnit'?: string;
    /**
     * Date of birth
     * @type {string}
     * @memberof UserData
     */
    'dateOfBirth': string;
    /**
     * Date of baptism
     * @type {string}
     * @memberof UserData
     */
    'dateOfBaptism'?: string;
    /**
     * Date of confirmation
     * @type {string}
     * @memberof UserData
     */
    'dateOfConfirmation'?: string;
    /**
     * Date of marriage
     * @type {string}
     * @memberof UserData
     */
    'dateOfMarriage'?: string;
    /**
     * Home parish
     * @type {string}
     * @memberof UserData
     */
    'homeParish'?: string;
    /**
     * Diocese in India
     * @type {string}
     * @memberof UserData
     */
    'dioceseInIndia'?: string;
    /**
     * Previous Parish in Canada
     * @type {string}
     * @memberof UserData
     */
    'previousParishInCanada'?: string;
    /**
     * Email ID of user
     * @type {string}
     * @memberof UserData
     */
    'email': string;
    /**
     * Indicates primary user
     * @type {boolean}
     * @memberof UserData
     */
    'isPrimary'?: boolean;
    /**
     * Gender
     * @type {string}
     * @memberof UserData
     */
    'gender': UserDataGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'relation'?: UserDataRelationEnum;
    /**
     * 
     * @type {string}
     * @memberof UserData
     */
    'maritalStatus'?: UserDataMaritalStatusEnum;
    /**
     * Apartment Number
     * @type {string}
     * @memberof UserData
     */
    'apartment'?: string;
    /**
     * Street number and name
     * @type {string}
     * @memberof UserData
     */
    'street'?: string;
    /**
     * City
     * @type {string}
     * @memberof UserData
     */
    'city'?: string;
    /**
     * Status in Canada
     * @type {string}
     * @memberof UserData
     */
    'canadianStatus'?: UserDataCanadianStatusEnum;
    /**
     * In Canada since
     * @type {string}
     * @memberof UserData
     */
    'inCanadaSince'?: string;
    /**
     * Profession
     * @type {string}
     * @memberof UserData
     */
    'profession'?: string;
    /**
     * Province
     * @type {string}
     * @memberof UserData
     */
    'province'?: UserDataProvinceEnum;
    /**
     * Postal Code
     * @type {string}
     * @memberof UserData
     */
    'postalCode'?: string;
    /**
     * Mobile number
     * @type {string}
     * @memberof UserData
     */
    'mobile'?: string;
}

export const UserDataGenderEnum = {
    Male: 'male',
    Female: 'female'
} as const;

export type UserDataGenderEnum = typeof UserDataGenderEnum[keyof typeof UserDataGenderEnum];
export const UserDataRelationEnum = {
    Spouse: 'spouse',
    Child: 'child',
    Parent: 'parent',
    GrandParent: 'grandParent',
    Sibling: 'sibling',
    Other: 'other'
} as const;

export type UserDataRelationEnum = typeof UserDataRelationEnum[keyof typeof UserDataRelationEnum];
export const UserDataMaritalStatusEnum = {
    Single: 'single',
    Married: 'married',
    Widowed: 'widowed',
    Separated: 'separated',
    Divorced: 'divorced'
} as const;

export type UserDataMaritalStatusEnum = typeof UserDataMaritalStatusEnum[keyof typeof UserDataMaritalStatusEnum];
export const UserDataCanadianStatusEnum = {
    Citizen: 'citizen',
    PermanentResident: 'permanentResident',
    Visitor: 'visitor',
    Student: 'student',
    WorkPermit: 'workPermit'
} as const;

export type UserDataCanadianStatusEnum = typeof UserDataCanadianStatusEnum[keyof typeof UserDataCanadianStatusEnum];
export const UserDataProvinceEnum = {
    Ab: 'AB',
    Bc: 'BC',
    Mb: 'MB',
    Nb: 'NB',
    Nl: 'NL',
    Nt: 'NT',
    Ns: 'NS',
    Nu: 'NU',
    On: 'ON',
    Pe: 'PE',
    Qc: 'QC',
    Sk: 'SK',
    Yt: 'YT'
} as const;

export type UserDataProvinceEnum = typeof UserDataProvinceEnum[keyof typeof UserDataProvinceEnum];

/**
 * 
 * @export
 * @interface UserId
 */
export interface UserId {
    /**
     * 
     * @type {string}
     * @memberof UserId
     */
    'userId'?: string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a background image
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBackgroundImage: async (image: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            assertParamExists('addBackgroundImage', 'image', image)
            const localVarPath = `/api/v1/admin/images/backgrounds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add an item to the carousel
         * @param {any} image 
         * @param {string} [title] 
         * @param {string} [subtitle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCarouselItem: async (image: any, title?: string, subtitle?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'image' is not null or undefined
            assertParamExists('addCarouselItem', 'image', image)
            const localVarPath = `/api/v1/admin/carousel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (title !== undefined) { 
                localVarFormParams.append('title', title as any);
            }
    
            if (subtitle !== undefined) { 
                localVarFormParams.append('subtitle', subtitle as any);
            }
    
            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add content to a page
         * @param {'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location'} pageId The unique identifier for the page
         * @param {PageContent} pageContent Page content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPageContent: async (pageId: 'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location', pageContent: PageContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('addPageContent', 'pageId', pageId)
            // verify required parameter 'pageContent' is not null or undefined
            assertParamExists('addPageContent', 'pageContent', pageContent)
            const localVarPath = `/api/v1/admin/pages/{pageId}`
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pageContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add members to admin group
         * @param {Array<string>} requestBody User id\&#39;s of new members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToAdminGroup: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('addToAdminGroup', 'requestBody', requestBody)
            const localVarPath = `/api/v1/admin/admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an item from the backgrounds
         * @param {string} backgroundImageItemId The unique identifier for the background image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBackgroundImage: async (backgroundImageItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'backgroundImageItemId' is not null or undefined
            assertParamExists('deleteBackgroundImage', 'backgroundImageItemId', backgroundImageItemId)
            const localVarPath = `/api/v1/admin/images/backgrounds/{backgroundImageItemId}`
                .replace(`{${"backgroundImageItemId"}}`, encodeURIComponent(String(backgroundImageItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an item from the carousel
         * @param {string} carouselItemId The unique identifier for the carousel item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCarouselItem: async (carouselItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'carouselItemId' is not null or undefined
            assertParamExists('deleteCarouselItem', 'carouselItemId', carouselItemId)
            const localVarPath = `/api/v1/admin/carousel/{carouselItemId}`
                .replace(`{${"carouselItemId"}}`, encodeURIComponent(String(carouselItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete content of a page
         * @param {'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location'} pageId The unique identifier for the page
         * @param {string} contentId Unique identifier of the page content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePageContent: async (pageId: 'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location', contentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('deletePageContent', 'pageId', pageId)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('deletePageContent', 'contentId', contentId)
            const localVarPath = `/api/v1/admin/pages/{pageId}/{contentId}`
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download users in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/download/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of users with Admin access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmins: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of background images
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBackgroundImages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/images/backgrounds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List users in the system
         * @param {string} [start] Start page token
         * @param {number} [limit] Number of results to be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers: async (start?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove members from admin group
         * @param {Array<string>} requestBody User id\&#39;s of members to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromAdminGroup: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('removeFromAdminGroup', 'requestBody', requestBody)
            const localVarPath = `/api/v1/admin/admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search family members by name or email
         * @param {string} q first name, last name or email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFamilyMembers: async (q: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('searchFamilyMembers', 'q', q)
            const localVarPath = `/api/v1/admin/search/familymembers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search signed up users by name or email
         * @param {string} q first name, last name or email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSignedUpUsers: async (q: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('searchSignedUpUsers', 'q', q)
            const localVarPath = `/api/v1/admin/search/signedupusers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update page content
         * @param {'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location'} pageId The unique identifier for the page
         * @param {string} contentId Unique identifier of the page content
         * @param {PageContent} pageContent Updated page content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePageContent: async (pageId: 'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location', contentId: string, pageContent: PageContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('updatePageContent', 'pageId', pageId)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('updatePageContent', 'contentId', contentId)
            // verify required parameter 'pageContent' is not null or undefined
            assertParamExists('updatePageContent', 'pageContent', pageContent)
            const localVarPath = `/api/v1/admin/pages/{pageId}/{contentId}`
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pageContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a background image
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBackgroundImage(image: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addBackgroundImage(image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add an item to the carousel
         * @param {any} image 
         * @param {string} [title] 
         * @param {string} [subtitle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCarouselItem(image: any, title?: string, subtitle?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCarouselItem(image, title, subtitle, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add content to a page
         * @param {'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location'} pageId The unique identifier for the page
         * @param {PageContent} pageContent Page content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPageContent(pageId: 'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location', pageContent: PageContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPageContent(pageId, pageContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add members to admin group
         * @param {Array<string>} requestBody User id\&#39;s of new members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addToAdminGroup(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addToAdminGroup(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an item from the backgrounds
         * @param {string} backgroundImageItemId The unique identifier for the background image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBackgroundImage(backgroundImageItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBackgroundImage(backgroundImageItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an item from the carousel
         * @param {string} carouselItemId The unique identifier for the carousel item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCarouselItem(carouselItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCarouselItem(carouselItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete content of a page
         * @param {'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location'} pageId The unique identifier for the page
         * @param {string} contentId Unique identifier of the page content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePageContent(pageId: 'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location', contentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePageContent(pageId, contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download users in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of users with Admin access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdmins(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicUserInfoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdmins(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of background images
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBackgroundImages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BackgroundImageItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBackgroundImages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List users in the system
         * @param {string} [start] Start page token
         * @param {number} [limit] Number of results to be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsers(start?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicUserInfoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsers(start, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove members from admin group
         * @param {Array<string>} requestBody User id\&#39;s of members to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFromAdminGroup(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFromAdminGroup(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search family members by name or email
         * @param {string} q first name, last name or email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchFamilyMembers(q: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicUserInfoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchFamilyMembers(q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search signed up users by name or email
         * @param {string} q first name, last name or email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchSignedUpUsers(q: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicUserInfoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchSignedUpUsers(q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update page content
         * @param {'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location'} pageId The unique identifier for the page
         * @param {string} contentId Unique identifier of the page content
         * @param {PageContent} pageContent Updated page content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePageContent(pageId: 'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location', contentId: string, pageContent: PageContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePageContent(pageId, contentId, pageContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a background image
         * @param {any} image 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBackgroundImage(image: any, options?: any): AxiosPromise<void> {
            return localVarFp.addBackgroundImage(image, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add an item to the carousel
         * @param {any} image 
         * @param {string} [title] 
         * @param {string} [subtitle] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCarouselItem(image: any, title?: string, subtitle?: string, options?: any): AxiosPromise<void> {
            return localVarFp.addCarouselItem(image, title, subtitle, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add content to a page
         * @param {'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location'} pageId The unique identifier for the page
         * @param {PageContent} pageContent Page content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPageContent(pageId: 'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location', pageContent: PageContent, options?: any): AxiosPromise<void> {
            return localVarFp.addPageContent(pageId, pageContent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add members to admin group
         * @param {Array<string>} requestBody User id\&#39;s of new members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToAdminGroup(requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.addToAdminGroup(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an item from the backgrounds
         * @param {string} backgroundImageItemId The unique identifier for the background image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBackgroundImage(backgroundImageItemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBackgroundImage(backgroundImageItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an item from the carousel
         * @param {string} carouselItemId The unique identifier for the carousel item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCarouselItem(carouselItemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCarouselItem(carouselItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete content of a page
         * @param {'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location'} pageId The unique identifier for the page
         * @param {string} contentId Unique identifier of the page content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePageContent(pageId: 'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location', contentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePageContent(pageId, contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download users in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsers(options?: any): AxiosPromise<any> {
            return localVarFp.downloadUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of users with Admin access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmins(options?: any): AxiosPromise<BasicUserInfoList> {
            return localVarFp.getAdmins(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of background images
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBackgroundImages(options?: any): AxiosPromise<Array<BackgroundImageItem>> {
            return localVarFp.getBackgroundImages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List users in the system
         * @param {string} [start] Start page token
         * @param {number} [limit] Number of results to be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(start?: string, limit?: number, options?: any): AxiosPromise<BasicUserInfoList> {
            return localVarFp.listUsers(start, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove members from admin group
         * @param {Array<string>} requestBody User id\&#39;s of members to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromAdminGroup(requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.removeFromAdminGroup(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search family members by name or email
         * @param {string} q first name, last name or email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFamilyMembers(q: string, options?: any): AxiosPromise<BasicUserInfoList> {
            return localVarFp.searchFamilyMembers(q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search signed up users by name or email
         * @param {string} q first name, last name or email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSignedUpUsers(q: string, options?: any): AxiosPromise<BasicUserInfoList> {
            return localVarFp.searchSignedUpUsers(q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update page content
         * @param {'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location'} pageId The unique identifier for the page
         * @param {string} contentId Unique identifier of the page content
         * @param {PageContent} pageContent Updated page content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePageContent(pageId: 'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location', contentId: string, pageContent: PageContent, options?: any): AxiosPromise<void> {
            return localVarFp.updatePageContent(pageId, contentId, pageContent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @summary Add a background image
     * @param {any} image 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public addBackgroundImage(image: any, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).addBackgroundImage(image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add an item to the carousel
     * @param {any} image 
     * @param {string} [title] 
     * @param {string} [subtitle] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public addCarouselItem(image: any, title?: string, subtitle?: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).addCarouselItem(image, title, subtitle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add content to a page
     * @param {'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location'} pageId The unique identifier for the page
     * @param {PageContent} pageContent Page content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public addPageContent(pageId: 'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location', pageContent: PageContent, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).addPageContent(pageId, pageContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add members to admin group
     * @param {Array<string>} requestBody User id\&#39;s of new members
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public addToAdminGroup(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).addToAdminGroup(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an item from the backgrounds
     * @param {string} backgroundImageItemId The unique identifier for the background image.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteBackgroundImage(backgroundImageItemId: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteBackgroundImage(backgroundImageItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an item from the carousel
     * @param {string} carouselItemId The unique identifier for the carousel item.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteCarouselItem(carouselItemId: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deleteCarouselItem(carouselItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete content of a page
     * @param {'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location'} pageId The unique identifier for the page
     * @param {string} contentId Unique identifier of the page content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deletePageContent(pageId: 'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location', contentId: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).deletePageContent(pageId, contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download users in the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public downloadUsers(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).downloadUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of users with Admin access
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdmins(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getAdmins(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of background images
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getBackgroundImages(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).getBackgroundImages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List users in the system
     * @param {string} [start] Start page token
     * @param {number} [limit] Number of results to be returned
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public listUsers(start?: string, limit?: number, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).listUsers(start, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove members from admin group
     * @param {Array<string>} requestBody User id\&#39;s of members to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public removeFromAdminGroup(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).removeFromAdminGroup(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search family members by name or email
     * @param {string} q first name, last name or email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public searchFamilyMembers(q: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).searchFamilyMembers(q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search signed up users by name or email
     * @param {string} q first name, last name or email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public searchSignedUpUsers(q: string, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).searchSignedUpUsers(q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update page content
     * @param {'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location'} pageId The unique identifier for the page
     * @param {string} contentId Unique identifier of the page content
     * @param {PageContent} pageContent Updated page content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public updatePageContent(pageId: 'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location', contentId: string, pageContent: PageContent, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).updatePageContent(pageId, contentId, pageContent, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FamilyManagementApi - axios parameter creator
 * @export
 */
export const FamilyManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add family members
         * @param {Array<UserData>} userData Array of family members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFamilyMembers: async (userData: Array<UserData>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userData' is not null or undefined
            assertParamExists('addFamilyMembers', 'userData', userData)
            const localVarPath = `/api/v1/user/family`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete family members
         * @param {Array<string>} requestBody Array of family member id\&#39;s
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFamilyMembers: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteFamilyMembers', 'requestBody', requestBody)
            const localVarPath = `/api/v1/user/family`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user family details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFamily: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/family`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update family member
         * @param {string} userId The globally unique identifier for the user.
         * @param {UserData} userData Family member detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFamilyMember: async (userId: string, userData: UserData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateFamilyMember', 'userId', userId)
            // verify required parameter 'userData' is not null or undefined
            assertParamExists('updateFamilyMember', 'userData', userData)
            const localVarPath = `/api/v1/user/family/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FamilyManagementApi - functional programming interface
 * @export
 */
export const FamilyManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FamilyManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add family members
         * @param {Array<UserData>} userData Array of family members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFamilyMembers(userData: Array<UserData>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FamilyId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFamilyMembers(userData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete family members
         * @param {Array<string>} requestBody Array of family member id\&#39;s
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFamilyMembers(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFamilyMembers(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user family details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserFamily(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserFamily(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update family member
         * @param {string} userId The globally unique identifier for the user.
         * @param {UserData} userData Family member detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFamilyMember(userId: string, userData: UserData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFamilyMember(userId, userData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FamilyManagementApi - factory interface
 * @export
 */
export const FamilyManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FamilyManagementApiFp(configuration)
    return {
        /**
         * 
         * @summary Add family members
         * @param {Array<UserData>} userData Array of family members
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFamilyMembers(userData: Array<UserData>, options?: any): AxiosPromise<FamilyId> {
            return localVarFp.addFamilyMembers(userData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete family members
         * @param {Array<string>} requestBody Array of family member id\&#39;s
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFamilyMembers(requestBody: Array<string>, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.deleteFamilyMembers(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user family details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFamily(options?: any): AxiosPromise<Array<UserData>> {
            return localVarFp.getUserFamily(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update family member
         * @param {string} userId The globally unique identifier for the user.
         * @param {UserData} userData Family member detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFamilyMember(userId: string, userData: UserData, options?: any): AxiosPromise<UserId> {
            return localVarFp.updateFamilyMember(userId, userData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FamilyManagementApi - object-oriented interface
 * @export
 * @class FamilyManagementApi
 * @extends {BaseAPI}
 */
export class FamilyManagementApi extends BaseAPI {
    /**
     * 
     * @summary Add family members
     * @param {Array<UserData>} userData Array of family members
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamilyManagementApi
     */
    public addFamilyMembers(userData: Array<UserData>, options?: AxiosRequestConfig) {
        return FamilyManagementApiFp(this.configuration).addFamilyMembers(userData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete family members
     * @param {Array<string>} requestBody Array of family member id\&#39;s
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamilyManagementApi
     */
    public deleteFamilyMembers(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return FamilyManagementApiFp(this.configuration).deleteFamilyMembers(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user family details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamilyManagementApi
     */
    public getUserFamily(options?: AxiosRequestConfig) {
        return FamilyManagementApiFp(this.configuration).getUserFamily(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update family member
     * @param {string} userId The globally unique identifier for the user.
     * @param {UserData} userData Family member detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FamilyManagementApi
     */
    public updateFamilyMember(userId: string, userData: UserData, options?: AxiosRequestConfig) {
        return FamilyManagementApiFp(this.configuration).updateFamilyMember(userId, userData, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get carousel items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarouselItems: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/public/carousel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get page contents
         * @param {'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location'} pageId The unique identifier for the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageContents: async (pageId: 'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('getPageContents', 'pageId', pageId)
            const localVarPath = `/api/v1/public/pages/{pageId}`
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get carousel items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCarouselItems(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarouselItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCarouselItems(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get page contents
         * @param {'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location'} pageId The unique identifier for the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPageContents(pageId: 'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PageContent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPageContents(pageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicApiFp(configuration)
    return {
        /**
         * 
         * @summary Get carousel items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarouselItems(options?: any): AxiosPromise<Array<CarouselItem>> {
            return localVarFp.getCarouselItems(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get page contents
         * @param {'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location'} pageId The unique identifier for the page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageContents(pageId: 'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location', options?: any): AxiosPromise<Array<PageContent>> {
            return localVarFp.getPageContents(pageId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI {
    /**
     * 
     * @summary Get carousel items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getCarouselItems(options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).getCarouselItems(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get page contents
     * @param {'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location'} pageId The unique identifier for the page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getPageContents(pageId: 'catechism' | 'services' | 'committee' | 'homepage' | 'history' | 'location', options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).getPageContents(pageId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserManagementApi - axios parameter creator
 * @export
 */
export const UserManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Provides user profile information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserManagementApi - functional programming interface
 * @export
 */
export const UserManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Provides user profile information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserManagementApi - factory interface
 * @export
 */
export const UserManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserManagementApiFp(configuration)
    return {
        /**
         * 
         * @summary Provides user profile information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options?: any): AxiosPromise<User> {
            return localVarFp.getUser(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserManagementApi - object-oriented interface
 * @export
 * @class UserManagementApi
 * @extends {BaseAPI}
 */
export class UserManagementApi extends BaseAPI {
    /**
     * 
     * @summary Provides user profile information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public getUser(options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).getUser(options).then((request) => request(this.axios, this.basePath));
    }
}


